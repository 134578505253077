import { useState, useEffect, useCallback } from "react";
import http from "../utils/http";

let mediaStream = null;
let recorder = null;

function ScreenRecorder(id) {
    const [chunks, setChunks] = useState([]);

    const handleStartRecording = () => {
        const options = { mimeType: "video/webm; codecs=vp9" };

        navigator.mediaDevices.getDisplayMedia({
            audio:true,
            video: {
                mediaSource: 'screen',
                cursor: 'always',
                displaySurface: 'monitor',
                frameRate: { ideal: 60, max: 120 }
            }
        }).then((stream) => {
            mediaStream = stream;
            recorder = new MediaRecorder(stream, options);

            let interval;
            recorder.onstart = () => {
                interval = setInterval(() => {
                    recorder.requestData();
                }, 5000);
            };

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            recorder.onstop = () => {
                clearInterval(interval);
            };

            recorder.start();
        });
    };

    const saveVideo = useCallback((url, blob) => {
        const formData = new FormData();
        formData.append('data', blob, 'video.webm');
        formData.append('url', url);
        http.post(`/api/v1/saveRecordingVideo/${id}`, formData)
            .then((response) => console.log(response.data))
            .catch((error) => console.log(error));
    }, [id]);

    useEffect(() => {
        if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: "video/webm" });
            const url = URL.createObjectURL(blob);
            saveVideo(url, blob);
        }
    }, [chunks, saveVideo]);

    const stopRecording = () => {
        // console.log("Clicked On Stop");
        if (recorder && recorder.state === "recording") {
            recorder.stop();
        }
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
        }
    };

    return { handleStartRecording, stopRecording, recorder };
}

export default ScreenRecorder;
