import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from "./utils/http";
import { SET_USER, SET_TOKEN } from "./store/auth";
import { getFirebaseToken } from "./utils/firebase";

import Login from "./pages/Login";
import AdminLayout from "./layouts";
import Dashboard from "./pages/Dashboard";
import ExamList from "./pages/ExamList";
import Instruction from "./pages/Instruction";
import Exam from "./pages/Exam";

import AssessorDashboard from "./pages/assessor/AssessorDashboard";
import AssessorBatchList from "./pages/assessor/BatchList";
import AssessmentToday from "./pages/assessor/AssessmentToday";
import AssessmentUpcomming from "./pages/assessor/AssessmentUpcomming";
import AssessmentCompleted from "./pages/assessor/AssessmentCompleted";
import AssessmentPending from "./pages/assessor/AssessmentPending";
import CandidateList from "./pages/assessor/CandidateList";
import Attendance from "./pages/assessor/Attendance";
import CandidateViva from "./pages/assessor/CandidateViva";
import CandidateDemo from "./pages/assessor/CandidateDemo";
import Practical from "./pages/Practical";
import LiveExam from "./pages/assessor/LiveExam";

const token = localStorage.getItem("token");

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    document.querySelector("title").innerHTML = process.env.REACT_APP_TITLE;
    if (token) {
      http.get('/api/v1/me').then((res) => {
        dispatch(SET_USER(res.data));
      }).catch((error) => {
        dispatch(SET_USER({}));
        dispatch(SET_TOKEN(''));
      })
    } else {
      dispatch(SET_USER({}));
      dispatch(SET_TOKEN(''));
    }
    return () => true
  }, [dispatch]);

  useEffect(() => {
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log('Firebase token: ', firebaseToken);
        localStorage.setItem('firebase_token', firebaseToken);
      })
      .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
  }, []);

  if (user == null) {
    return <div className="flex h-screen w-screen items-center justify-center">
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {Object.keys(user).length > 0 ? <><Route path="/" element={<AdminLayout />}>
            {user.role.name === 'candidate' ? <>
              <Route index element={<Dashboard />} />
              <Route path="exam-list" element={<ExamList />} />
              <Route path="instruction/:id" element={<Instruction />} />
            </> : <>
              <Route index element={<AssessorDashboard />} />
              <Route path="batch-list" element={<AssessorBatchList />} />
              <Route path="today-assessment" element={<AssessmentToday />} />
              <Route path="upcomming-assessment" element={<AssessmentUpcomming />} />
              <Route path="completed-assessment" element={<AssessmentCompleted />} />
              <Route path="pending-assessment" element={<AssessmentPending />} />
              <Route path="candidate/:id" element={<CandidateList />} />
              <Route path="live-exam/:id" element={<LiveExam />} />
            </>}
          </Route>
            {user.role.name === 'candidate' && <Route path="practical" element={<Practical />} />}
            {user.role.name === 'candidate' && <Route path="exam/:id" element={<Exam />} />}
            {user.role.name === 'assessor' && <Route path="attendance/:id" element={<Attendance />} />}
            {user.role.name === 'assessor' && <Route path="viva/:assessment_id/:candidate_id" element={<CandidateViva />} />}
            {user.role.name === 'assessor' && <Route path="demo/:assessment_id/:candidate_id" element={<CandidateDemo />} />}
          </> : <>
            <Route path="/" element={<Login />}></Route>
            {/* <Route path="practical/:meetingID/:role" element={<Practical />}></Route> */}
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </>
          }
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
  );
}

export default App;
