import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import http from "../utils/http";
import Camera from "../components/Camera";
import GoogleMap from "../components/GoogleMap";
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Instruction = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);
    const [exam, setExam] = React.useState({});
    const [cam, setCam] = React.useState({ photo: false, id: false, image_with_id: false });
    const [photo, setPhoto] = React.useState(null);
    const [ID, setId] = React.useState(null);
    const [photoId, setPhotoId] = React.useState(null);
    const [location, setLocation] = React.useState({});
    const [instModel, setInsModel] = React.useState(false);
    const [verifyModel, setVerifyModel] = React.useState(false);
    const [verifyData, setVerifyData] = React.useState(user);

    const success = position => {
        const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }
        setLocation(currentPosition);
    };
    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    });

    const getExams = React.useCallback(() => {
        http.get(`/api/v1/exam-instruction/${id}`).then((res) => setExam(res.data));
    }, [id]);

    React.useEffect(() => {
        getExams()
    }, [getExams]);

    const getVerification = React.useCallback(() => {
        http.get(`/api/v1/me`).then((res) => setVerifyData(res.data));
    }, []);

    React.useEffect(() => {
        var interval = null;
        if (exam?.candidate_verification) {
            interval = setInterval(() => {
                getVerification();
            }, 120000);
        }

        return () => clearInterval(interval);
    }, [exam, getVerification]);

    const submit = () => {
        const f = new FormData();
        if (photo) f.append('image', photo, 'candidate.png');
        if (ID) f.append('adhaar', ID, 'adhaar.png');
        if (photoId) f.append('image_with_id', photoId, 'image_with_id.png');
        f.append('location', JSON.stringify(location));
        http.post('/api/v1/profile-update-web', f, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            toast.success('Candidate details saved successfully.');
            setInsModel(false);
            if (exam?.candidate_verification) {
                getVerification();
                setVerifyModel(true);
            } else {
                navigate(`/exam/${id}`);
            }
        }).catch(error => {
            toast.warn(error.response.data.message);
        })
    };

    const openInstructionModel = (event) => {
        event.preventDefault();
        setInsModel(true);
    };

    const verificationStatus = () => {
        let status = verifyData?.verification?.filter(v => v.status === true).length;
        if (status > 0) {
            return true;
        }
        return false;
    }

    return <div>
        <div className="breadcrum">
            <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                <h2 className="assess-f">Instruction</h2>
                <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-[14px] lg:text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white dash-f">
                                <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                Dashboard
                            </Link>
                        </li>
                        <li className="inline-flex items-center">
                            <Link to="/exam-list" className="inline-flex items-center text-[14px] lg:text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white dash-f">
                                <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                Exam List
                            </Link>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                <span className="ml-1 text-[14px] lg:text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500 dash-f">Instruction</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
            <form onSubmit={openInstructionModel}>
                <table className="w-full text-sm text-left">
                    <thead className="textxs-xs">
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Assessment Name
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.name}</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Description/Instructions
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]'>
                                <div dangerouslySetInnerHTML={{ __html: exam?.strategy?.instruction }}></div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Duration
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.strategy?.duration} Minutes</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Total Question
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.strategy?.sets?.[0]?.questions?.length}</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Start Date/time
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.start_date && moment(exam.start_date).format('YYYY-MMM-DD hh:mm A')}</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                End Date/time
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.end_date && moment(exam.end_date).format('YYYY-MMM-DD hh:mm A')}</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Percentage required to pass
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.strategy?.pass_percentage} %</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                SSC
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.sector?.name}</td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                TP
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="col" className="lg:px-6 text-[14px] py-3 width-20 font-b">
                                Batch ID
                            </th>
                            <td className="width-3 font-b">:</td>
                            <td className='text-[14px]' >{exam?.batch?.batch_id}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <table className="w-full text-sm mar-bot-top">
                                    <tbody>
                                        <tr>
                                            <td className='w-4/5 lg:w-1/2 text-center'>
                                                {!cam.photo ? <div className='flex flex-col items-center'><img width="240px" src={`${user?.image ? user?.image : '/camera.png'}`} alt='id' onClick={() => setCam({ ...cam, photo: true })} /></div> :
                                                    <Camera onChange={setPhoto} />}
                                                <span><b>Candidate Image</b></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='w-4/5 lg:w-1/2  text-center'>
                                                {!cam.id ? <div className='flex flex-col items-center'><img width="240px" src={`${user?.aadhar_image ? user?.aadhar_image : '/camera.png'}`} alt='aadhar' onClick={() => setCam({ ...cam, id: true })} /></div> :
                                                    <Camera onChange={setId} />}
                                                <span><b>Candidate ID Image</b></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='w-4/5 lg:w-1/2 text-center'>
                                                {!cam.image_with_id ? <div className='flex flex-col items-center'><img width="240px" src={`${user?.image_with_id ? user?.image_with_id : '/camera.png'}`} alt='image_with_id' onClick={() => setCam({ ...cam, image_with_id: true })} /></div> :
                                                    <Camera onChange={setPhotoId} />}
                                                <span><b>Candidate Photo with ID</b></span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <div className="flex items-start mb-6">
                                    <div className="flex items-center h-5">
                                        <input id="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
                                    </div>
                                    <label htmlFor="remember" required className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tick this checkbox, if you have read all instructions and ready to attempt quiz/test.</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Start Assessment</button>
                            </td>
                        </tr>
                    </thead>
                </table>
            </form>
        </div>
        {Object.keys(location).length > 0 && <GoogleMap location={{ ...location, address: '' }} zoomLevel={7} />}
        {instModel ? (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{ zIndex: 101 }} >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-medium">
                                    Exam Instructions
                                </h3>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto overflow-y-auto" style={{ height: 400 }}>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    Read carefully before you begin.
                                </p>

                                <h3>Exam Instructions (Remote Proctored Examination)</h3>
                                <ul className='being-f mb-5' style={{ listStyle: 'disc', marginLeft: '37px' }}>
                                    <li>As soon as the proctor authorizes you, your assessment will start, and timer will begin</li>
                                    <li>The test answers are getting automatically saved on our platform.</li>
                                    <li>You are not allowed to leave your seat during the test.</li>
                                    <li>You are not allowed to use mobile phones, calculators or other devices during the test.</li>
                                    <li>You can use a paper and pen for rough work and calculations. If required, the proctor may request you to show your rough work.</li>
                                    <li>Please ensure all other software/applications that might cause pop ups are turned off.</li>
                                    <li>You are not allowed to switch the browsing window during the test. You will be provided with 2 warnings and the test window will automatically close on the third attempt.</li>
                                    <li>Please ensure that you are not running / attempt to start any remote access software before or during the test. As this will automatically close your test browser.</li>
                                    <li>You are not allowed to connect any extended screen/projector along with the system being used during the test.</li>
                                    <li>You must keep a valid photo ID (attached during registration) handy before you start and during the test.</li>
                                    <li>Before you start the test, you will be required to take a picture of yourself/ selfie and your identity card at the relevant sections.</li>
                                    <li>No one should be sitting/standing near you during the test.</li>
                                    <li>Please ensure you are the only person in the room during the test and there is no disturbance.</li>
                                    <li>At any point of time during the test, you can be asked by the proctor through Chat, to turn your Laptop/Webcam to show your surroundings.</li>
                                    <li>Please ensure that there is proper lighting in the room.</li>
                                    <li>This is to inform you that your exam is being monitored by the proctor and the entire examination session is recorded for future audit purposes</li>
                                </ul>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    I understand that this examination is a remotely proctored online test. If I do not abide by the instruction, rules and regulations, the invigilator has a right to end my examination. Any violation of the above, would prompt the assessment body to cancel my examination and take necessary disciplinary action. By taking this examination, I agree that I will abide by all the terms and conditions of the this online examination regulations.
                                </p>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-medium px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setInsModel(false)}
                                >
                                    Decline
                                </button>
                                <button
                                    className="bg-blue-700 text-white active:bg-blue-600 font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => submit()}
                                >
                                    I Agree Terms & Conditions
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ zIndex: 100 }}></div>
            </>
        ) : null}
        {verifyModel && <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{ zIndex: 101 }} >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-medium">
                                Verification Status
                            </h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-4 flex-auto overflow-y-auto" style={{ height: 400 }}>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 py-2">
                                Wait 5 to 10 minute for verification from backend team.
                            </p>
                            {verifyData?.verification.length > 0 && <table>
                                <thead>
                                    <tr className='border'>
                                        <th style={{ width: 55 }}>Sr. No.</th>
                                        <th>Reason</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {verifyData?.verification.map((v, i) => <tr key={i}>
                                        <td className='text-[14px]' >{i + 1}</td>
                                        <td className='text-[14px]' >{v.reason}</td>
                                        <td className='text-[14px]' >{v.status ? 'Verified' : 'Rejected'}</td>
                                    </tr>)}
                                </tbody>
                            </table>}
                            {!verificationStatus() && <div className='flex w-full items-center justify-center'>
                                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>}
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className={`${verificationStatus() ? 'bg-red-300 active:bg-red-300' : 'bg-red-700 active:bg-red-600'} text-white font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                                type="button"
                                disabled={verificationStatus()}
                                onClick={() => setVerifyModel(false)}
                            >
                                Cancel & upload again
                            </button>
                            <button
                                className={`${verificationStatus() ? 'bg-blue-700 active:bg-blue-600' : 'bg-blue-400 active:bg-blue-300'} text-white font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                                type="button"
                                disabled={!verificationStatus()}
                                onClick={() => navigate(`/exam/${id}`)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ zIndex: 100 }}></div>
        </>}
    </div>;
};

export default Instruction;